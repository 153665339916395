import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next"

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";

function mapStateToProps(state) {
  return {
    setLanguage: state.setLanguage,
  };
}

const LanguageSwitch = (props, { asContainer = false }) => {

  const { i18n } = useTranslation()

  useEffect(() => {
    if (props.setLanguage) {
      window.location.reload()
    }
  }, [props.setLanguage])

  const Switch = () => {
    const newLng = i18n.resolvedLanguage === "en" ? "pl" : "en"
    const languageData = {
      language: newLng
    }
    if (props.ppvCode) {
      languageData.ppvCode = props.ppvCode
    }
    return (
      <Button
        id={`lng-${newLng}`}
        color={"secondary"}
        onClick={() => {
          i18n.changeLanguage(newLng)
          props.actions.setLanguage(languageData)
        }}
      >
        {newLng}
      </Button>
    );
  };

  if (asContainer) {
    return (
      <Grid item container justifyContent={"center"}>
        <Switch />
      </Grid>
    );
  }
  return (
    <Grid item>
      <Switch />
    </Grid>
  );
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);