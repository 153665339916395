export const getTeamShortName = (team) => {
  if (team.name) {
    if (team.name.length < 20) {
      return team.name;
    } else {
      const teamName = team.name.substring(0, 20) + "...";
      return teamName;
    }
  }
  return team.code;
}