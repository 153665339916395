import React, { useState, useEffect, createContext } from "react";
import { Provider } from "react-redux";
import store from "./store";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as themes from "./components/utils/theme";

import "@fontsource/poppins";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
// import { ThemeContext } from '@emotion/react';
import "./i18n";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import { CookieConsentProvider } from "./components/CookieConsentProvider";

const darkTheme = createTheme(themes.darkTheme);
const brightTheme = createTheme(themes.lightTheme);

const getThemeBasedOnStorageItem = () => {
  const isDark = typeof window !== "undefined" && window.localStorage.getItem("darkTheme") === "on";
  return isDark ? darkTheme : brightTheme;
};

export default function FullApp({ children }) {
  const [theme, setTheme] = useState(getThemeBasedOnStorageItem());
  const [localDarkTheme, setLocalDarkTheme] = useState(false);

  const lng = typeof window !== "undefined" && window.localStorage.getItem("i18nextLng") === "pl" ? pl : en;

  useEffect(() => {
    if (typeof window === "undefined") return null;
    const onStorage = () => {
      setTheme(getThemeBasedOnStorageItem());
    };

    window.addEventListener("storage", onStorage);
    return () => window.removeEventListener("storage", onStorage);
  }, []);

  useEffect(() => {
    let theme = localStorage.getItem("darkTheme");
    if (theme === "on") {
      setLocalDarkTheme(true);
    } else {
      setLocalDarkTheme(false);
    }
  }, [theme]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={lng}>
          <CookieConsentProvider>
            <ThemeChangeContext.Provider value={{ localDarkTheme }}>{children}</ThemeChangeContext.Provider>
          </CookieConsentProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}

export const ThemeChangeContext = createContext();
