import React, { useEffect, useState } from "react";
import { Divider, Menu, MenuItem } from "@mui/material";
import { navigate } from "gatsby";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import i18next, { t } from "i18next";
import { ADD_SHOP_ITEM } from "../../constants";

import { teamTrans as enTeamTrans } from "../../../locales/en/team";
import { teamTrans as plTeamTrans } from "../../../locales/pl/team";
import { settingsTrans as enSettingsTrans } from "../../../locales/en/settings";
import { settingsTrans as plSettingsTrans } from "../../../locales/pl/settings";
import { layoutTrans as enLayoutTrans } from "../../../locales/en/layout";
import { layoutTrans as plLayoutTrans } from "../../../locales/pl/layout";

i18next.addResourceBundle("en", "layout", enLayoutTrans);
i18next.addResourceBundle("pl", "layout", plLayoutTrans);
i18next.addResourceBundle("en", "team", enTeamTrans);
i18next.addResourceBundle("pl", "team", plTeamTrans);
i18next.addResourceBundle("en", "settings", enSettingsTrans);
i18next.addResourceBundle("pl", "settings", plSettingsTrans);

function mapStateToProps(state) {
  return {};
}

const TeamMenu = ({ teamMenuOpen, teamMenuAnchorEl,
  setTeamMenuAnchorEl, user }) => {
    const [cartItemsNumber, setCartItemsNumber] = useState(
      (typeof window !== "undefined" && window.localStorage.getItem("cartItemsNumber")) || 0
    );
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCurrentTeam, setIsCurrentTeam] = useState(false);

  useEffect(() => {
    const updateCart = (event) => {
      setCartItemsNumber(event.detail);
    };
    window.addEventListener("updateCart", updateCart);

    return () => {
      window.removeEventListener("updateCart", updateCart);
    };
  }, [])

  useEffect(() => {

    if (teamMenuOpen) {

      const currentTeam = user.teams?.find((team) => team.code === user.currentTeamCodeContext);
      setIsAdmin(currentTeam?.admin)
      setIsCurrentTeam(!!currentTeam)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMenuOpen, user]);

  const handleClose = () => {
    setTeamMenuAnchorEl(null);
  };

  const handleCloseAndNavigate = (path) => {
    setTeamMenuAnchorEl(null);
    navigate(path);
  };

  return (
    <Menu
      id="menu"
      aria-labelledby="menu-open-button"
      anchorEl={teamMenuAnchorEl}
      open={teamMenuOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      maxWidth="md"
    >
      <MenuItem
       disabled={!isCurrentTeam}
       onClick={() => handleCloseAndNavigate("/dashboard/risk-management?tab-primary=parties")}>
        {t("layout:layout.select.rule_settings", "Rule settings")}
      </MenuItem>
      <MenuItem
       disabled={!isCurrentTeam}
       onClick={() => handleCloseAndNavigate("/dashboard/settings/storage")}>
        {t("settings:storage.title", "Storage")}
      </MenuItem>
      <MenuItem
       disabled={!isCurrentTeam}
       onClick={() => handleCloseAndNavigate("/dashboard/settings/api")}>
        {t("settings:api_integration.title", "API integration")}
      </MenuItem>
      <Divider/>
      <MenuItem
       disabled={!isCurrentTeam || (isCurrentTeam && !isAdmin)}
       onClick={() => {
          actions.clearState(ADD_SHOP_ITEM)
          handleCloseAndNavigate("/dashboard/products")
        }}>
        {t("layout:layout.select.products", "Products")}
      </MenuItem>
      <MenuItem
       disabled={!isCurrentTeam || (isCurrentTeam && !isAdmin)}
       onClick={() => handleCloseAndNavigate("/dashboard/cart")}>
        {isAdmin
          ?`${t("layout:layout.select.cart", "Cart")} (${cartItemsNumber})`
          : t("layout:layout.select.cart", "Cart")
        }
      </MenuItem>
      <MenuItem
       disabled={!isCurrentTeam || (isCurrentTeam && !isAdmin)}
       onClick={() => handleCloseAndNavigate("/dashboard/orders")}>
        {t("layout:layout.select.orders", "Orders")}
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleCloseAndNavigate("/dashboard/teams")}>
        {t("layout:layout.select.teams", "Teams")}
      </MenuItem>
    </Menu>
  );
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamMenu);
