import React, { createContext, useState } from "react";
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useTheme } from "@mui/material/styles";

import i18next, { t } from "i18next";
import { commonTrans as enCommonTrans } from "../../locales/en/common";
import { commonTrans as plCommonTrans } from "../../locales/pl/common";

i18next.addResourceBundle("en", "common", enCommonTrans);
i18next.addResourceBundle("pl", "common", plCommonTrans);

export const CookieConsentContext = createContext();

export const CookieConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(Cookies.get("gatsby-gdpr-google-tagmanager") === "true");
  const theme = useTheme(); // Użyj hooka useTheme, aby uzyskać aktualny motyw

  const handleAccept = () => {
    Cookies.set("gatsby-gdpr-google-tagmanager", "true", { expires: 365, sameSite: 'None', secure: true });
    initializeAndTrack(window.location);
    setConsent(true);
  };

  const handleDecline = () => {
    Cookies.set("gatsby-gdpr-google-tagmanager", "false", { expires: 365, sameSite: 'None', secure: true });
    setConsent(false);
  };

  const buttonAcceptColor = theme.palette.mode === "dark" ? "black" : "white";

  return (
    <CookieConsentContext.Provider value={{ consent, handleAccept, handleDecline }}>
      {children}
      <CookieConsent
        location="bottom"
        buttonText={t("common:accept_all", "Accept all")}
        enableDeclineButton
        declineButtonText={t("common:reject_all", "Reject all")}
        cookieName="gatsby-gdpr-google-tagmanager"
        onAccept={handleAccept}
        onDecline={handleDecline}
        style={{
          zIndex: 9999,
          fontFamily: theme.typography.fontFamily, // Użyj czcionki z motywu
        }}
        buttonStyle={{
          fontFamily: theme.typography.fontFamily, // Użyj czcionki z motywu
          backgroundColor: theme.palette.secondary.main,
          color: buttonAcceptColor,
          borderRadius: "4px", // Dodaj zaokrąglenie rogów
        }}
        declineButtonStyle={{
          fontFamily: theme.typography.fontFamily, // Użyj czcionki z motywu
          backgroundColor: theme.palette.highlighted.main,
          color: theme.palette.text.primary,
          borderRadius: "4px", // Dodaj zaokrąglenie rogów
        }}
      >
        {t(
          "common:cookie_text",
          "We use cookies to make the site work better, but also to see how you interact with it. " +
            'We will only use cookies if you allow us to do so by clicking "Accept Cookies".'
        )}
      </CookieConsent>
    </CookieConsentContext.Provider>
  );
};
