import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { bindActionCreators } from "redux";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Badge, CircularProgress, Paper, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WorkIcon from '@mui/icons-material/Work';
import SettingsIcon from '@mui/icons-material/Settings';

import { MainNavItems, SecondaryNavItems } from "./navItems";
import Footer from "../../components/footer.js";
import SubscriptionErrorAlert from "../utils/SubscriptionErrorAlert";

import i18next from "i18next";
import { commonTrans as enCommonTrans } from "../../../locales/en/common";
import { commonTrans as plCommonTrans } from "../../../locales/pl/common";
import TeamMenu from "./TeamMenu.js";
import UserMenu from "./UserMenu.js";
import ChangeTeamContextMenu from "./ChangeTeamContextMenu";
import { getTeamShortName } from "../teams/getTeamShortName.js";

i18next.addResourceBundle("en", "common", enCommonTrans);
i18next.addResourceBundle("pl", "common", plCommonTrans);

function mapStateToProps(state) {
  return {
    getUser: state.getUser,
    checkSubscription: state.checkSubscription,
  };
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function Layout(props) {
  const initialDrawerPosition =
    (typeof window !== "undefined" && window.localStorage.getItem("drawerPosition")) || "open";

  const [cartItemsNumber, setCartItemsNumber] = useState(
    (typeof window !== "undefined" && window.localStorage.getItem("cartItemsNumber")) || 0
  );
  const [open, setOpen] = useState(initialDrawerPosition === "open");
  const [user, setUser] = useState({
    email: "",
    newAlertsAmount: 0,
    newTasksAmount: 0,
    giif: false,
    roles: [],
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [teamMenuAnchorEl, setTeamMenuAnchorEl] = useState(null);
  const [changeTeamContextMenuAnchorEl, setChangeTeamContextMenuAnchorEl] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [currentContextTeam, setCurrentContextTeam] = useState({name: null, type: 'individual'});

  const menuOpen = Boolean(anchorEl);
  const teamMenuOpen = Boolean(teamMenuAnchorEl);
  const changeTeamContextMenuOpen = Boolean(changeTeamContextMenuAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenChangeTeamContextMenu = (e) => {
    setChangeTeamContextMenuAnchorEl(e.currentTarget);
  }
  const handleOpenTeamMenu = (e) => {
    setTeamMenuAnchorEl(e.currentTarget);
  }

  useEffect(() => {
    const updateCart = (event) => {
      setCartItemsNumber(event.detail);
    };

    window.addEventListener("updateCart", updateCart);

    return () => {
      window.removeEventListener("updateCart", updateCart);
    };
  }, []);

  useEffect(() => {
    if (props.location.pathname.substring(0, 10) === "/dashboard") {
      props.actions.checkSubscription();
      props.actions.userCheckLoggedIn();
      if (props.getUser === null) {
        props.actions.getUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.location.state?.checkLogin === true) {
      props.actions.checkSubscription();
      props.actions.userCheckLoggedIn();
      if (props.getUser === null) {
        props.actions.getUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state]);

  useEffect(() => {
    if (props.getUser) {
      if (props.getUser.data) {
        const {data} = props.getUser;
        setUser(data)
        const currentTeam = data.teams.find((team) => team.code === data.currentTeamCodeContext)
        setCurrentContextTeam(currentTeam)
      }
    }
  }, [props.getUser]);

  useEffect(() => {
    if (props.checkSubscription !== null) {
      setSubscriptionLoading(false);
      props.actions.toggleSubscription(true);
      if (props.checkSubscription.error) {
        setSubscriptionError(props.checkSubscription.error);
        props.actions.toggleSubscription(false);
      }
      else setSubscriptionError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkSubscription]);

  const toggleDrawer = () => {
    if (open) {
      setOpen(false);
      localStorage.setItem("drawerPosition", "mid");
    } else {
      setOpen(true);
      localStorage.setItem("drawerPosition", "open");
    }
  };

  if (props.location.pathname.substring(0, 10) !== "/dashboard") {
    return props.children;
  }
  if (props.children.key === "/404.html") {
    return props.children;
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} color="default">
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: { xs: "8px", sm: "32px" },
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid
                item
                sx={{
                  display: (theme) => (theme.palette.mode === "light" ? "none" : "display"),
                  width: { xs: "150px", sm: "200px" },
                }}
              >
                <Link to="/dashboard/">
                  <StaticImage
                    src="../../images/SystemAMLlight.png"
                    alt="System AML"
                    placeholder="none"
                    quality="100"
                  />
                </Link>
              </Grid>
              <Grid
                item
                sx={{
                  display: (theme) => (theme.palette.mode === "dark" ? "none" : "display"),
                  width: { xs: "150px", sm: "200px" },
                }}
              >
                <Link to="/dashboard/">
                  <StaticImage src="../../images/SystemAMLdark.png" alt="System AML" placeholder="none" quality="100" />
                </Link>
              </Grid>

              <Grid item>
                {currentContextTeam?.admin && cartItemsNumber > 0 && (
                  <IconButton component={Link} to="/dashboard/cart" sx={{mr:1}}>
                    <Badge showZero badgeContent={cartItemsNumber} color="secondary">
                      <ShoppingCartCheckoutIcon color="primary" />
                    </Badge>
                  </IconButton>
                )}

                <Button
                  // color="primary"
                  id="menu-open-button"
                  aria-controls={menuOpen ? "menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? "true" : undefined}
                  onClick={handleOpenChangeTeamContextMenu}
                  sx={{maxWidth: {sm:"210px", md: "300px", lg: "500px"}}}
                >
                  <Typography variant="h7" display={{ xs: "none", sm: "block" }}>
                    {getTeamShortName(currentContextTeam? currentContextTeam : {name: null, type: 'individual'})}
                  </Typography>
                  <WorkIcon fontSize="large" sx={{ paddingLeft: "8px" }} />
                </Button>
                <IconButton onClick={handleOpenTeamMenu}>
                  <SettingsIcon />
                </IconButton >
                <IconButton onClick={handleClick}>
                  <AccountCircleIcon color="secondary"/>
                </IconButton >
              </Grid>
            </Grid>
            <ChangeTeamContextMenu
              user={user}
              changeTeamContextMenuOpen={changeTeamContextMenuOpen}
              changeTeamContextMenuAnchorEl={changeTeamContextMenuAnchorEl}
              setChangeTeamContextMenuAnchorEl={setChangeTeamContextMenuAnchorEl}
            />
            <TeamMenu
              user={user}
              isAdmin={currentContextTeam?.admin}
              isCurrentTeam={currentContextTeam?.code === user?.currentContextTeam}
              teamMenuOpen={teamMenuOpen}
              teamMenuAnchorEl={teamMenuAnchorEl}
              setTeamMenuAnchorEl={setTeamMenuAnchorEl}
            />
            <UserMenu
              user={user}
              handleClick={handleClick}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              menuOpen={menuOpen}
              setSubscriptionError={() => setSubscriptionError(false)}
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <MainNavItems
              newAlertsAmount={user.newAlertsAmount}
              newTasksAmount={user.newTasksAmount}
              user={props.getUser?.data}
            />
          </List>
          <Divider />
          <List>
            <SecondaryNavItems giif={user.giif} />
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: "calc(100vh - 132px)",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {subscriptionLoading ? (
                <Stack sx={{ width: "100%" }} mt={2} spacing={2} p={2}>
                  <CircularProgress color="secondary" sx={{ margin: "0 auto" }} />
                </Stack>
              ) : (
                <>
                  {subscriptionError && (
                    <Grid item xs={12}>
                      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Grid px={2}>
                          <SubscriptionErrorAlert message={subscriptionError} withButton={true} />
                        </Grid>
                      </Paper>
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={12}>
                {props.children}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Divider />
      <Footer sx={{ pt: 4 }} />
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
