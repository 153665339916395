import React from "react";
import FullApp from "./src/fullapp";
import Layout from "./src/components/layout/layout";
import "./styles.css";
import { createRoot } from "react-dom/client";

export const wrapRootElement = ({ element }) => {
  return <FullApp>{element}</FullApp>;
};

export function wrapPageElement({element, props}) {
  return <Layout {...props}>{element}</Layout>
}

export function replaceHydrateFunction() {
  return (element, container) => {
    const root = createRoot(container);
    root.render(element);
  };
}
