exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aml-form-[code]-js": () => import("./../../../src/pages/aml-form/[code].js" /* webpackChunkName: "component---src-pages-aml-form-[code]-js" */),
  "component---src-pages-aml-panel-[code]-js": () => import("./../../../src/pages/aml-panel/[code].js" /* webpackChunkName: "component---src-pages-aml-panel-[code]-js" */),
  "component---src-pages-ankiety-instytucje-obowiazane-js": () => import("./../../../src/pages/ankiety/instytucje-obowiazane.js" /* webpackChunkName: "component---src-pages-ankiety-instytucje-obowiazane-js" */),
  "component---src-pages-ankiety-js": () => import("./../../../src/pages/ankiety.js" /* webpackChunkName: "component---src-pages-ankiety-js" */),
  "component---src-pages-ankiety-wymogi-ustawy-aml-js": () => import("./../../../src/pages/ankiety/wymogi-ustawy-aml.js" /* webpackChunkName: "component---src-pages-ankiety-wymogi-ustawy-aml-js" */),
  "component---src-pages-dashboard-admin-logswitch-js": () => import("./../../../src/pages/dashboard/admin/logswitch.js" /* webpackChunkName: "component---src-pages-dashboard-admin-logswitch-js" */),
  "component---src-pages-dashboard-alerts-[code]-js": () => import("./../../../src/pages/dashboard/alerts/[code].js" /* webpackChunkName: "component---src-pages-dashboard-alerts-[code]-js" */),
  "component---src-pages-dashboard-alerts-js": () => import("./../../../src/pages/dashboard/alerts.js" /* webpackChunkName: "component---src-pages-dashboard-alerts-js" */),
  "component---src-pages-dashboard-applicants-[code]-js": () => import("./../../../src/pages/dashboard/applicants/[code].js" /* webpackChunkName: "component---src-pages-dashboard-applicants-[code]-js" */),
  "component---src-pages-dashboard-applicants-create-js": () => import("./../../../src/pages/dashboard/applicants/create.js" /* webpackChunkName: "component---src-pages-dashboard-applicants-create-js" */),
  "component---src-pages-dashboard-cart-confirmation-index-js": () => import("./../../../src/pages/dashboard/cart/confirmation/index.js" /* webpackChunkName: "component---src-pages-dashboard-cart-confirmation-index-js" */),
  "component---src-pages-dashboard-cart-index-js": () => import("./../../../src/pages/dashboard/cart/index.js" /* webpackChunkName: "component---src-pages-dashboard-cart-index-js" */),
  "component---src-pages-dashboard-customer-due-diligence-[code]-js": () => import("./../../../src/pages/dashboard/customer-due-diligence/[code].js" /* webpackChunkName: "component---src-pages-dashboard-customer-due-diligence-[code]-js" */),
  "component---src-pages-dashboard-customer-due-diligence-index-js": () => import("./../../../src/pages/dashboard/customer-due-diligence/index.js" /* webpackChunkName: "component---src-pages-dashboard-customer-due-diligence-index-js" */),
  "component---src-pages-dashboard-employees-[code]-js": () => import("./../../../src/pages/dashboard/employees/[code].js" /* webpackChunkName: "component---src-pages-dashboard-employees-[code]-js" */),
  "component---src-pages-dashboard-employees-create-js": () => import("./../../../src/pages/dashboard/employees/create.js" /* webpackChunkName: "component---src-pages-dashboard-employees-create-js" */),
  "component---src-pages-dashboard-giif-transaction-[type]-js": () => import("./../../../src/pages/dashboard/giif/transaction/[type].js" /* webpackChunkName: "component---src-pages-dashboard-giif-transaction-[type]-js" */),
  "component---src-pages-dashboard-giif-transaction-index-js": () => import("./../../../src/pages/dashboard/giif/transaction/index.js" /* webpackChunkName: "component---src-pages-dashboard-giif-transaction-index-js" */),
  "component---src-pages-dashboard-giif-upload-js": () => import("./../../../src/pages/dashboard/giif/upload.js" /* webpackChunkName: "component---src-pages-dashboard-giif-upload-js" */),
  "component---src-pages-dashboard-help-js": () => import("./../../../src/pages/dashboard/help.js" /* webpackChunkName: "component---src-pages-dashboard-help-js" */),
  "component---src-pages-dashboard-history-event-list-[code]-js": () => import("./../../../src/pages/dashboard/history-event-list/[code].js" /* webpackChunkName: "component---src-pages-dashboard-history-event-list-[code]-js" */),
  "component---src-pages-dashboard-history-event-list-create-js": () => import("./../../../src/pages/dashboard/history-event-list/create.js" /* webpackChunkName: "component---src-pages-dashboard-history-event-list-create-js" */),
  "component---src-pages-dashboard-history-event-list-js": () => import("./../../../src/pages/dashboard/history-event-list.js" /* webpackChunkName: "component---src-pages-dashboard-history-event-list-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-orders-[code]-js": () => import("./../../../src/pages/dashboard/orders/[code].js" /* webpackChunkName: "component---src-pages-dashboard-orders-[code]-js" */),
  "component---src-pages-dashboard-orders-js": () => import("./../../../src/pages/dashboard/orders.js" /* webpackChunkName: "component---src-pages-dashboard-orders-js" */),
  "component---src-pages-dashboard-orders-pricing-[plan]-js": () => import("./../../../src/pages/dashboard/orders/pricing/[plan].js" /* webpackChunkName: "component---src-pages-dashboard-orders-pricing-[plan]-js" */),
  "component---src-pages-dashboard-orders-pricing-create-confirmation-js": () => import("./../../../src/pages/dashboard/orders/pricing/create/confirmation.js" /* webpackChunkName: "component---src-pages-dashboard-orders-pricing-create-confirmation-js" */),
  "component---src-pages-dashboard-orders-pricing-index-js": () => import("./../../../src/pages/dashboard/orders/pricing/index.js" /* webpackChunkName: "component---src-pages-dashboard-orders-pricing-index-js" */),
  "component---src-pages-dashboard-parties-[code]-js": () => import("./../../../src/pages/dashboard/parties/[code].js" /* webpackChunkName: "component---src-pages-dashboard-parties-[code]-js" */),
  "component---src-pages-dashboard-parties-create-applicant-js": () => import("./../../../src/pages/dashboard/parties/create-applicant.js" /* webpackChunkName: "component---src-pages-dashboard-parties-create-applicant-js" */),
  "component---src-pages-dashboard-parties-create-js": () => import("./../../../src/pages/dashboard/parties/create.js" /* webpackChunkName: "component---src-pages-dashboard-parties-create-js" */),
  "component---src-pages-dashboard-parties-csv-js": () => import("./../../../src/pages/dashboard/parties/csv.js" /* webpackChunkName: "component---src-pages-dashboard-parties-csv-js" */),
  "component---src-pages-dashboard-parties-js": () => import("./../../../src/pages/dashboard/parties.js" /* webpackChunkName: "component---src-pages-dashboard-parties-js" */),
  "component---src-pages-dashboard-products-index-js": () => import("./../../../src/pages/dashboard/products/index.js" /* webpackChunkName: "component---src-pages-dashboard-products-index-js" */),
  "component---src-pages-dashboard-risk-management-js": () => import("./../../../src/pages/dashboard/risk-management.js" /* webpackChunkName: "component---src-pages-dashboard-risk-management-js" */),
  "component---src-pages-dashboard-rules-[code]-edit-js": () => import("./../../../src/pages/dashboard/rules/[code]/edit.js" /* webpackChunkName: "component---src-pages-dashboard-rules-[code]-edit-js" */),
  "component---src-pages-dashboard-rules-[code]-index-js": () => import("./../../../src/pages/dashboard/rules/[code]/index.js" /* webpackChunkName: "component---src-pages-dashboard-rules-[code]-index-js" */),
  "component---src-pages-dashboard-rules-profile-create-js": () => import("./../../../src/pages/dashboard/rules/profile/create.js" /* webpackChunkName: "component---src-pages-dashboard-rules-profile-create-js" */),
  "component---src-pages-dashboard-rules-profile-profile-code-js": () => import("./../../../src/pages/dashboard/rules/profile/[profileCode].js" /* webpackChunkName: "component---src-pages-dashboard-rules-profile-profile-code-js" */),
  "component---src-pages-dashboard-settings-api-js": () => import("./../../../src/pages/dashboard/settings/api.js" /* webpackChunkName: "component---src-pages-dashboard-settings-api-js" */),
  "component---src-pages-dashboard-settings-index-js": () => import("./../../../src/pages/dashboard/settings/index.js" /* webpackChunkName: "component---src-pages-dashboard-settings-index-js" */),
  "component---src-pages-dashboard-settings-storage-js": () => import("./../../../src/pages/dashboard/settings/storage.js" /* webpackChunkName: "component---src-pages-dashboard-settings-storage-js" */),
  "component---src-pages-dashboard-storage-js": () => import("./../../../src/pages/dashboard/storage.js" /* webpackChunkName: "component---src-pages-dashboard-storage-js" */),
  "component---src-pages-dashboard-subscriptions-js": () => import("./../../../src/pages/dashboard/subscriptions.js" /* webpackChunkName: "component---src-pages-dashboard-subscriptions-js" */),
  "component---src-pages-dashboard-tasks-[code]-js": () => import("./../../../src/pages/dashboard/tasks/[code].js" /* webpackChunkName: "component---src-pages-dashboard-tasks-[code]-js" */),
  "component---src-pages-dashboard-tasks-create-js": () => import("./../../../src/pages/dashboard/tasks/create.js" /* webpackChunkName: "component---src-pages-dashboard-tasks-create-js" */),
  "component---src-pages-dashboard-tasks-js": () => import("./../../../src/pages/dashboard/tasks.js" /* webpackChunkName: "component---src-pages-dashboard-tasks-js" */),
  "component---src-pages-dashboard-teams-[code]-js": () => import("./../../../src/pages/dashboard/teams/[code].js" /* webpackChunkName: "component---src-pages-dashboard-teams-[code]-js" */),
  "component---src-pages-dashboard-teams-create-js": () => import("./../../../src/pages/dashboard/teams/create.js" /* webpackChunkName: "component---src-pages-dashboard-teams-create-js" */),
  "component---src-pages-dashboard-teams-index-js": () => import("./../../../src/pages/dashboard/teams/index.js" /* webpackChunkName: "component---src-pages-dashboard-teams-index-js" */),
  "component---src-pages-dashboard-tools-index-js": () => import("./../../../src/pages/dashboard/tools/index.js" /* webpackChunkName: "component---src-pages-dashboard-tools-index-js" */),
  "component---src-pages-dashboard-trainings-js": () => import("./../../../src/pages/dashboard/trainings.js" /* webpackChunkName: "component---src-pages-dashboard-trainings-js" */),
  "component---src-pages-dashboard-transactions-[code]-js": () => import("./../../../src/pages/dashboard/transactions/[code].js" /* webpackChunkName: "component---src-pages-dashboard-transactions-[code]-js" */),
  "component---src-pages-dashboard-transactions-create-js": () => import("./../../../src/pages/dashboard/transactions/create.js" /* webpackChunkName: "component---src-pages-dashboard-transactions-create-js" */),
  "component---src-pages-dashboard-transactions-js": () => import("./../../../src/pages/dashboard/transactions.js" /* webpackChunkName: "component---src-pages-dashboard-transactions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kompendium-js": () => import("./../../../src/pages/kompendium.js" /* webpackChunkName: "component---src-pages-kompendium-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-koszyk-index-js": () => import("./../../../src/pages/koszyk/index.js" /* webpackChunkName: "component---src-pages-koszyk-index-js" */),
  "component---src-pages-koszyk-zamowienie-index-js": () => import("./../../../src/pages/koszyk/zamowienie/index.js" /* webpackChunkName: "component---src-pages-koszyk-zamowienie-index-js" */),
  "component---src-pages-koszyk-zamowienie-logowanie-lub-rejestracja-index-js": () => import("./../../../src/pages/koszyk/zamowienie/logowanie-lub-rejestracja/index.js" /* webpackChunkName: "component---src-pages-koszyk-zamowienie-logowanie-lub-rejestracja-index-js" */),
  "component---src-pages-koszyk-zamowienie-potwierdzenie-index-js": () => import("./../../../src/pages/koszyk/zamowienie/potwierdzenie/index.js" /* webpackChunkName: "component---src-pages-koszyk-zamowienie-potwierdzenie-index-js" */),
  "component---src-pages-kyc-[code]-js": () => import("./../../../src/pages/kyc/[code].js" /* webpackChunkName: "component---src-pages-kyc-[code]-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-not-allowed-in-test-js": () => import("./../../../src/pages/not-allowed-in-test.js" /* webpackChunkName: "component---src-pages-not-allowed-in-test-js" */),
  "component---src-pages-produkty-cennik-js": () => import("./../../../src/pages/produkty/cennik.js" /* webpackChunkName: "component---src-pages-produkty-cennik-js" */),
  "component---src-pages-produkty-index-js": () => import("./../../../src/pages/produkty/index.js" /* webpackChunkName: "component---src-pages-produkty-index-js" */),
  "component---src-pages-produkty-procedura-aml-js": () => import("./../../../src/pages/produkty/procedura-aml.js" /* webpackChunkName: "component---src-pages-produkty-procedura-aml-js" */),
  "component---src-pages-produkty-sprawdzenie-aml-js": () => import("./../../../src/pages/produkty/sprawdzenie-aml.js" /* webpackChunkName: "component---src-pages-produkty-sprawdzenie-aml-js" */),
  "component---src-pages-regulamin-index-js": () => import("./../../../src/pages/regulamin/index.js" /* webpackChunkName: "component---src-pages-regulamin-index-js" */),
  "component---src-pages-user-account-delete-[code]-js": () => import("./../../../src/pages/user/account-delete/[code].js" /* webpackChunkName: "component---src-pages-user-account-delete-[code]-js" */),
  "component---src-pages-user-email-verification-[code]-js": () => import("./../../../src/pages/user/email-verification/[code].js" /* webpackChunkName: "component---src-pages-user-email-verification-[code]-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user/login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-register-js": () => import("./../../../src/pages/user/register.js" /* webpackChunkName: "component---src-pages-user-register-js" */),
  "component---src-pages-user-reset-password-[code]-js": () => import("./../../../src/pages/user/reset-password/[code].js" /* webpackChunkName: "component---src-pages-user-reset-password-[code]-js" */),
  "component---src-pages-user-reset-password-js": () => import("./../../../src/pages/user/reset-password.js" /* webpackChunkName: "component---src-pages-user-reset-password-js" */),
  "component---src-pages-user-team-invite-[code]-js": () => import("./../../../src/pages/user/team/invite/[code].js" /* webpackChunkName: "component---src-pages-user-team-invite-[code]-js" */),
  "component---src-pages-wyszukiwarka-list-sankcyjnych-js": () => import("./../../../src/pages/wyszukiwarka-list-sankcyjnych.js" /* webpackChunkName: "component---src-pages-wyszukiwarka-list-sankcyjnych-js" */)
}

