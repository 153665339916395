import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { FIBERPAY_FRONTEND_URL } from "../constants";
import LanguageSwitch from "./utils/LanguageSwitch";
import { useTranslation } from "react-i18next";

import i18next from "i18next";

import { footerTrans as enFooterTrans } from "../../locales/en/footer";
import { footerTrans as plFooterTrans } from "../../locales/pl/footer";

i18next.addResourceBundle("en", "footer", enFooterTrans);
i18next.addResourceBundle("pl", "footer", plFooterTrans);

const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.secondary.main,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function Footer({ ppvCode = null }) {
  const { i18n } = useTranslation();
  const { t } = i18n;
  const [localDarkTheme, setLocalDarkTheme] = useState(false);

  useEffect(() => {
    let theme = localStorage.getItem("darkTheme");
    if (theme === "on") {
      setLocalDarkTheme(true);
    } else {
      setLocalDarkTheme(false);
    }
  }, []);

  const handleThemeChange = () => {
    if (localDarkTheme) {
      localStorage.setItem("darkTheme", "off");
      window.dispatchEvent(new Event("storage"));
    } else {
      localStorage.setItem("darkTheme", "on");
      window.dispatchEvent(new Event("storage"));
    }
    setLocalDarkTheme(!localDarkTheme);
  };

  return (
    <Grid container>
      <Grid item xs={4} md={3} sx={{ pl: 4, pt: 4, pb: 4 }}></Grid>
      <Grid item container xs={4} md={6} sx={{ pt: 4, pb: 4 }} justifyContent={"center"} alignContent={"center"}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center" component="p" color="text.primary">
            {t("footer:title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" color="text.primary">
            {"Copyright © "}
            <Link color="secondary.main" variant="inherit" underline="none" href={FIBERPAY_FRONTEND_URL}>
              FiberPay
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={4} md={3} sx={{ pr: 4, pt: 4, pb: 4 }} justifyContent={"center"}>
        <LanguageSwitch asContainer={true} ppvCode={ppvCode} />
        <ThemeSwitch id="footer-theme-switch" checked={localDarkTheme} onChange={handleThemeChange} />
      </Grid>
    </Grid>
  );
}
