export const attachmentsTrans = {
  ft: {
    attachments: "Attachments",
    attachment: "Attachment",
    attachments_not_found: "No attachments found",
    connect_drive: "Connect your drive",
    no_drive_connected: "No drive is connected",
    drive_settings: "Drive settings",
    drive_attachments_requirements:
      "Adding attachments is available after connecting to the drive. You can do this in the settings",
    attachments_google_drive: "Google Drive attachments",
    attachments_one_drive: "Microsoft OneDrive attachments",
    attachment_removed: "Attachment removed",
    attachment_added: "Attachment added",
    description_updated: "Description updated",
    add_attachment: "Add attachment",
    remove_attachment: "Remove attachment",
    remove_attachment_question: "Are you sure you want to remove the attachment: {{fileName}}?",
    file_not_selected: "File not selected",
    select_file: "Select file",
    send: "Send",
    change_description: "Change description",
    without_description: "Without description",
    max_size: "Maximum file size is ",

    table: {
      code: "Code",
      description: "Description",
      createdTime: "Created time",
    },

    validation: {
      max_length: "Maximum description length is 255 characters",
    },
  },
};
