import React from "react";
import { Alert, Button, Stack } from "@mui/material";
import { Link } from "gatsby";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { dashboardTrans as enDashboardTrans } from "../../../locales/en/dashboard";
import { dashboardTrans as plDashboardTrans } from "../../../locales/pl/dashboard";

i18next.addResourceBundle("en", "dashboard", enDashboardTrans);
i18next.addResourceBundle("pl", "dashboard", plDashboardTrans);

const SubscriptionErrorAlert = ({ message, withButton, ...props }) => {
  const { i18n } = useTranslation();
  const { t } = i18n;
  if (withButton) {
    return (

    <Stack sx={{ width: "100%" }} mt={2} mb={2} spacing={2} px={2}>
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" component={Link} to="/dashboard/products/">
            {t("dashboard:subscription.choose_plan","Select a plan")}
          </Button>
        }
      >
        {message}
      </Alert>
    </Stack>
    )
  }

  return (
    <Stack sx={{ width: "100%" }} mt={2} mb={2} spacing={2}>
      <Alert severity="error">{message}</Alert>
    </Stack>
  );
};
export default SubscriptionErrorAlert;