import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import GradingIcon from "@mui/icons-material/Grading";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConstructionIcon from "@mui/icons-material/Construction";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import SchoolIcon from '@mui/icons-material/School';
import TaskIcon from "@mui/icons-material/Task";
import Badge from "@mui/material/Badge";

import { Link } from "gatsby";
import { ListItemButton } from "@mui/material";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { layoutTrans as enLayoutTrans } from "../../../locales/en/layout";
import { layoutTrans as plLayoutTrans } from "../../../locales/pl/layout";

i18next.addResourceBundle("en", "layout", enLayoutTrans);
i18next.addResourceBundle("pl", "layout", plLayoutTrans);

export function MainNavItems(props) {
  const { i18n } = useTranslation();
  const { t } = i18n;
  const newAlertsAmount = props.newAlertsAmount;
  const newTasksAmount = props.newTasksAmount;
  return (
    <>
      <ListItemButton component={Link} to="/dashboard/parties">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.main_nav.parties", "Parties")} />
      </ListItemButton>

      <ListItemButton component={Link} to="/dashboard/transactions">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.main_nav.transactions", "Transactions")} />
      </ListItemButton>

      <ListItemButton component={Link} to="/dashboard/history-event-list">
        <ListItemIcon>
          <GradingIcon />
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.main_nav.events", "Events")} />
      </ListItemButton>

      <ListItemButton component={Link} to="/dashboard/alerts">
        <ListItemIcon>
          <Badge badgeContent={newAlertsAmount} color="secondary">
            <AnnouncementIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.main_nav.alerts", "Alerts")} />
      </ListItemButton>

      <ListItemButton component={Link} to="/dashboard/tasks">
        <ListItemIcon>
          <Badge badgeContent={newTasksAmount} color="secondary">
            <TaskIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.main_nav.tasks", "Tasks")} />
      </ListItemButton>

      <ListItemButton component={Link} to="/dashboard/trainings">
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.main_nav.trainings", "Trainings")} />
      </ListItemButton>
    </>
  );
}

export const SecondaryNavItems = () => {
  const { i18n } = useTranslation();
  const { t } = i18n;
  return (
    <>
      <ListItemButton component={Link} to="/dashboard/tools?tab-primary=registries-skan">
        <ListItemIcon>
          <ConstructionIcon />
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.add_nav.tools", "Tools")} />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard/help">
        <ListItemIcon>
          <ImportContactsIcon />
        </ListItemIcon>
        <ListItemText primary={t("layout:layout.add_nav.help", "Help")} />
      </ListItemButton>
    </>
  );
};
