export const layoutTrans = {
  layout: {
    main_nav: {
      parties: "Podmioty",
      transactions: "Transakcje",
      events: "Zdarzenia",
      alerts: "Alerty",
      tasks: "Zadania",
      trainings: "Szkolenia",
    },
    add_nav: {
      tools: "Narzędzia",
      contact: "Kontakt",
      help: "Pomoc",
    },
    select: {
      teams: "Zespoły",
      rule_settings: "Ustawienia reguł",
      dashboard:"SystemAML",
      orders: "Zamówienia",
      products: "Produkty",
      subscription: "Abonament",
      settings: "Ustawienia konta",
      log_switch: "Przeloguj (admin)",
      payments: "Płatności",
      cart: "Koszyk",
    },
    logout_text: "Czy na pewno chcesz się wylogować?",
    subscription: {
      error: "Konto nie posiada aktywnego abonamentu!",
    }
  },
  landing_layout: {
    price_list: "Cennik",
    compendium: "Kompendium",
    procedure_aml: "Procedura AML",
    services: "Usługi",
    services_submenu: {
      sanction_lists: "Listy Sankcyjne",
    },
  },
};
