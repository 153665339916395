export const dashboardTrans = {
  subscription: {
    active:"Your subscription is active",
    error: "The account does not have an active subscription!",
    extend: "Extend the plan",
    using_trial: "You benefit from a free trial plan",
    left_days: "There are {{leftDays}} days left on your subscription.",
    left_days_in_current: "There are {{leftDays}} days left in the current plan.",
    plan_name: "Plan {{type}}",
    choose_plan: "Select a plan",
    plans: {
      standard: "Standard",
      minimum: "Minimum",
      premium: "Premium",
      trial: "Free trial period",
      monthPlan: "Monthly",
      quarterPlan: "Quarterly",
      yearPlan: "Annual",
      month: "Month",
      three_months: "3 months",
      twelve_months: "12 months",
    },
    plan_is_ending: "Your subscription is coming to an end",
    using_plan: "You are currently using a plan {{type}}"
  },
};
